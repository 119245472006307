$(document).ready(function(){

    /*
     * Slick slider
     */
    $('.themepic-slider').slick({
        dots: true,
        arrows: false,
        autoplay: true,
        //slidesToShow: 4,
        //infinite: true,
        //slidesToScroll: 1,
        swipe: true,
        // customPaging: function(slider, i) {
        //     var title = $(slider.$slides[i]).data('title');
        //     return '<a class="slider-main__nav-btn" href="javascript:void(0);">'+title+'</a>';
        // },
        // responsive: [
        //     {
        //         breakpoint: 1200,
        //         settings: {
        //             slidesToShow: 4,
        //             swipe: true,
        //             dots: true,
        //             arrows: false
        //         }
        //     },
        //     {
        //         breakpoint: 992,
        //         settings: {
        //             slidesToShow: 3,
        //             swipe: true,
        //             dots: true,
        //             arrows: false
        //         }
        //     },
        //     {
        //         breakpoint: 768,
        //         settings: {
        //             slidesToShow: 1,
        //             swipe: true,
        //             dots: true,
        //             arrows: false
        //         }
        //     }
        // ]
    });


    $('.gallery-slider__main').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: '.gallery-slider__nav'
    });

    $('.gallery-slider__nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        //asNavFor: '.gallery-slider__main',
        dots: false,
        arrows: true,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    dots: true,
                    arrows: false
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false
                }
            }
        ]
    });


    /*
     * Custom mobile nav
     */
    $(".icon-unfold").click(function(){

        $(this).toggleClass("icon-unfold--down");

        $(this).parents().closest('li').find('dropdown').addClass("open");

        var dropdown = $(this).next();

        dropdown.slideToggle();

    });


    $(".icon-search").click(function() {
        $("#searchterm").show().focus().css('opacity','1');
    });

    $(document).mouseup(function(e)
    {
        var search = $("#searchterm");

        // if the target of the click isn't the container nor a descendant of the container
        if (!search.is(e.target) && search.has(e.target).length === 0)
        {
            search.hide().css('opacity','0');
        }
    });


    /*
     * Fancybox
     */
    $(".fancybox").fancybox();



    $(window).scroll(function () {

        //console.log($(window).scrollTop());

        if ($(window).scrollTop() > 35) {
            $('.nav-outer').addClass('navbar-fixed');
            $('body').addClass('padding-top');
            $('.top-bar').hide();
        }


        if ($(window).scrollTop() < 36) {
            $('.nav-outer').removeClass('navbar-fixed');
            $('body').removeClass('padding-top');
            $('.top-bar').show();
        }

        // if ($(window).scrollTop() > 160) {
        //     $('.logo-small').addClass('visible');
        // }
        //
        // if ($(window).scrollTop() < 161) {
        //     $('.logo-small').removeClass('visible');
        // }

    });


    $(".agency-item.usa").hover(function(){
        $(".agency-item-wrapper.usa").toggleClass("active");
    });

    $(".agency-item.frankreich").hover(function(){
        $(".agency-item-wrapper.frankreich").toggleClass("active");
    });

    $(".agency-item.deutschland").hover(function(){
        $(".agency-item-wrapper.deutschland").toggleClass("active");
    });

    $(".agency-item.japan").hover(function(){
        $(".agency-item-wrapper.japan").toggleClass("active");
    });

    $(".agency-item.italien").hover(function(){
        $(".agency-item-wrapper.italien").toggleClass("active");
    });


});